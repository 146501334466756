/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@font-face {
    font-family: MabryBold;
    src: url('./theme/fonts/MabryPro-Bold.woff2');
    font-weight: bold;
}

@font-face {
    font-family: MabryBlack;
    src: url('./theme/fonts/MabryPro-Black.woff2');
    font-weight: bolder;
}

@font-face {
    font-family: Mabry;
    src: url('./theme/fonts/MabryPro-Regular.woff2');
    font-weight: normal;
}


@font-face {
    font-family: MabryLight;
    src: url('./theme/fonts/MabryPro-Light.woff2');

}

ion-content {
    --padding-bottom: 45px !important;
    --background: #F2F2F6;
}

* {
    font-family: Mabry;
}

ion-input:not(.normal) {

    // --color: #fff !important;
    // --placeholder-color: #ddd !important;
    // --placeholder-opacity: 0.8 !important;
    // --padding-bottom: 0px !important;
    // --padding-end:10px !important;

    border-radius: 2rem !important;
    background-color: var(--ion-color-primary-tint);
    --padding-start: 1rem !important;

    & input {}


    & .input-highlight {
        transform: scale(0) !important
    }

    &.ion-touched.ion-invalid {
        border: 1px solid var(--ion-color-danger);
    }

    &.ion-touched.ion-invalid {
        border: 1px solid var(--ion-color-danger);
    }

    &.ion-touched.ion-valid {
        border: 1px solid var(--ion-color-success);
    }
}

ion-button {
    font-weight: normal !important;
    --border-radius: 2rem !important;
    box-shadow: none !important;
    --border-color: #333 !important;

    border-width: 0px !important;
    border: none !important;

    &:not(.button-clear) {

        &::part(native) {

            box-shadow: none !important;
            font-family: MabryBlack !important;
        }
    }

}

ion-searchbar {
    --box-shadow: none !important;
    ;
    --border-radius: 2rem
}

.searchbar-input {
    border-radius: 2rem !important;
}

ion-card {
    &.primary-tint {
        background-color: var(--ion-color-primary-tint) !important;
    }

    box-shadow: none !important;
    border-radius: 2rem !important;

    &::part(native) {
        box-shadow: none !important;
    }
}


.center {
    text-align: center;
}

.link {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: var(--ion-color-tertiary)
    }
}




ion-item,
ion-input {
    --border-radius: 2rem !important;
    --border-width: 0 !important;
    border: none !important;
}

/* Note: These styles should be located in your global stylesheet */
ion-action-sheet.my-custom-class .action-sheet-group {
    background: var(--ion-color-dark);
}

ion-action-sheet.my-custom-class .action-sheet-title {
    color: var(--ion-color-dark-contrast);
    text-align: center;
}

ion-action-sheet.my-custom-class .action-sheet-cancel::after {
    background: var(--ion-color-dark);
}

ion-action-sheet.my-custom-class .action-sheet-button,
ion-action-sheet.my-custom-class .action-sheet-button.ion-focused {
    color: var(--ion-color-dark-contrast);
    text-align: center !important;
    font-weight: bolder !important;
}

@media (any-hover: hover) {
    ion-action-sheet.my-custom-class .action-sheet-button:hover {
        color: var(--ion-color-dark-contrast);
    }
}

ion-action-sheet.my-custom-class ion-backdrop {
    opacity: 0.6;
}

ion-loading.custom-loading {
    --background: var(--ion-color-primary);
    --spinner-color: var(--ion-color-primary-contrast);

    color: var(--ion-color-primary-contrast);
}

.alert-button.dark {
    color: var(--ion-color-dark) !important;

    & .alert-button-inner {
        color: var(--ion-color-dark) !important;
    }

    & .alert-radio-label {}
}

.alert-radio-button[aria-checked=true] {
    * {

        color: var(--ion-color-primary-shade) !important
    }

    & .alert-radio-inner {
        border-color: var(--ion-color-primary-shade) !important
    }
}

div[aria-checked=true].sc-ion-alert-md,
div[aria-checked=true].sc-ion-alert-ios {}

.alert-button {
    color: var(--ion-color-dark) !important;
}